import { APIServer } from '../api-server';
import BaseAPI from '../http/index';
import { SysModels } from './sys-models';

export default class SysAPI extends BaseAPI {
    
    /**
     * 设置语言
     */
    public static setLocale(info: SysModels.setLocalParams) {
        return new BaseAPI().get(APIServer.System +'/public/set_locale',info);
    }
    /**
     * 查询所有矿箱列表
     */
    public static getGtewayMachine(info: SysModels.getGatewayMachineParams) {
        return new BaseAPI().post(APIServer.System +'/machine/gateway/query',info);
    }

    /**
     * 当前用户管理的矿箱
     */
    public static queryMyGteway() {
        return new BaseAPI().post(APIServer.System +'/machine/gateway/queryMy');
    }

    /**
     * 矿箱明细
     */
    public static queryGtewayInfo(no:string) {
        return new BaseAPI().post(APIServer.System +'/machine/gateway/get',{ no },'form');
    }
    
    /**
     * 字典
     */
    public static getDict() {
        return new BaseAPI().get(APIServer.System +'/public/get_dict');
    }
    /**
     * 系统 - 命令
     */
    public static setCommand(command:number) {
        return new BaseAPI().post(APIServer.System +'/system/command',{command},'form');
    } 
    /**
     * 系统 - 系统信息
     */
    public static getSystemInfo() {
        return new BaseAPI().get(APIServer.System +'/system/info');
    }  
    
    /**
     * 警告列表
     */
     public static queryWarning(info?: SysModels.queryWarningParam) {
        return new BaseAPI().post(APIServer.System +'/log/warning/query',info||{},'body',false,false,false);
    } 
    /**
     * 处理告警
     */
    public static handleWarning(info: SysModels.handleWarningParam) {
        return new BaseAPI().post(APIServer.System +'/log/warning/handle',info);
    }  
    /**
     * 安全预警
     */
    public static getSystemSecurity() {
        return new BaseAPI().get(APIServer.System +'/system/security');
    }

    /**
     * 系统升级
     */     
    public static upgradeSystem(file:any) {
        return new BaseAPI().uploadFile(APIServer.System +'/system/upgrade',file);
    }


    /**
     * 查看系统网络设置
     */
    public static getSystemNetworkInfo() {
        return new BaseAPI().get(APIServer.System +'/system/network_info');
    }
    /**
     * 设置系统网络设置
     */
    public static setSystemNetworkSetting(info: SysModels.systemNetworkParam) {
        return new BaseAPI().post(APIServer.System +'/system/network_setting',info);
    }
    //查看smtp
    public static getMail() {
        return new BaseAPI().get(APIServer.System +'/sys/mail/query');
    }
    //更新smtp
    public static updateMail(info: SysModels.mailParam) {
        return new BaseAPI().post(APIServer.System +'/sys/mail/update',info);
    }
    public static saveMail(info: SysModels.mailParam) {
        return new BaseAPI().post(APIServer.System +'/sys/mail/save',info);
    }
    //测试smtp
    public static testMail(mailAddress:string) {
        return new BaseAPI().post(APIServer.System +'/sys/mail/test',{mailAddress},'form');
    }

    //登录账号安全保障设置
    public static checkMachineid() {
        return new BaseAPI().get(APIServer.System +'/sys/param/query/check_machineid');
    }
    public static setcheckMachineid(status:number) {
        return new BaseAPI().post(APIServer.System +'/sys/param/setCheckMachineId',{
            status
        },'form');
    }

}